import styled from 'styled-components';
import CardMedia from './CardMedia';
import {
  BREAKPOINT,
  FONT_FAMILY,
  COLOR,
  CARD_COLOR,
  FONT_WEIGHT
} from '../../utils/constants';

const CARD_PROPERTIES = {
  CARD_VERTICAL: {
    DESKTOP: {
      WIDTH: '122px',
      HEIGHT: '193px',
      PADDING: '16px',
      DESCRIPTION_MAX_WIDTH: '276px'
    },
    MOBILE: {
      WIDTH: '86px',
      HEIGHT: '137px',
      PADDING: '16px',
      DESCRIPTION_MAX_WIDTH: '207px'
    }
  },
  CARD_HORIZONTAL: {
    DESKTOP: {
      WIDTH: '167px',
      HEIGHT: '105px'
    },
    MOBILE: {
      WIDTH: '128px',
      HEIGHT: '81px'
    },
    PADDING: '16px'
  },
  PADDING_LEFT: '16px',
  PADDING_TOP: '16px'
};

const BrandedCard = styled(CardMedia)`
  &.card-media {
    box-shadow: none;
    position: relative;
    max-width: 445px;

    @media (min-width: ${BREAKPOINT.LG}) {
      min-width: 342px;
    }
  }

  &.card-media--tall {
    min-height: auto;
    @media (min-width: ${BREAKPOINT.LG}) {
      min-height: 640px;
    }
  }

  .card-media__background--cc {
    background-image: none;
    background-color: ${CARD_COLOR.BLUE_LIGHT};
  }

  .card-media__image {
    position: relative;
    overflow: visible;
    height: ${CARD_PROPERTIES.CARD_VERTICAL.MOBILE.HEIGHT};

    img {
      position: absolute;
      z-index: 3;
      top: -${CARD_PROPERTIES.CARD_VERTICAL.MOBILE.PADDING};
      right: ${CARD_PROPERTIES.CARD_VERTICAL.MOBILE.PADDING};
      width: ${CARD_PROPERTIES.CARD_VERTICAL.MOBILE.WIDTH};
      height: ${CARD_PROPERTIES.CARD_VERTICAL.MOBILE.HEIGHT};
    }

    @media (min-width: ${BREAKPOINT.LG}) {
      height: ${CARD_PROPERTIES.CARD_VERTICAL.MOBILE.HEIGHT};

      img {
        top: -${CARD_PROPERTIES.CARD_VERTICAL.DESKTOP.PADDING};
        right: ${CARD_PROPERTIES.CARD_VERTICAL.DESKTOP.PADDING};
        width: ${CARD_PROPERTIES.CARD_VERTICAL.DESKTOP.WIDTH};
        height: ${CARD_PROPERTIES.CARD_VERTICAL.DESKTOP.HEIGHT};
      }
    }

    &.card-media__image--horizontal {
      img {
        width: ${CARD_PROPERTIES.CARD_HORIZONTAL.MOBILE.WIDTH};
        height: ${CARD_PROPERTIES.CARD_HORIZONTAL.MOBILE.HEIGHT};
        top: ${CARD_PROPERTIES.CARD_HORIZONTAL.PADDING};
        right: ${CARD_PROPERTIES.CARD_HORIZONTAL.PADDING};

        @media (min-width: ${BREAKPOINT.LG}) {
          width: ${CARD_PROPERTIES.CARD_HORIZONTAL.DESKTOP.WIDTH};
          height: ${CARD_PROPERTIES.CARD_HORIZONTAL.DESKTOP.HEIGHT};
        }
      }
    }

    &.card-media__title--tall {
      height: 170px;

      @media (min-width: ${BREAKPOINT.LG}) {
        height: ${CARD_PROPERTIES.CARD_VERTICAL.MOBILE.HEIGHT};
      }
    }
  }

  .card-media__award_media {
    width: 61px;
    height: 66px;
    position: absolute;
    top: calc(${CARD_PROPERTIES.CARD_VERTICAL.MOBILE.HEIGHT} + 10px - ${CARD_PROPERTIES.CARD_VERTICAL.MOBILE.PADDING});
    right: calc(${CARD_PROPERTIES.CARD_VERTICAL.MOBILE.PADDING} + 10px);
    z-index: 2;

    img {
      width: 100%;
      height: auto;
      display: block;
    }

    @media (min-width: ${BREAKPOINT.LG}) {
      width: 83px;
      height: 89px;
      top: calc(${CARD_PROPERTIES.CARD_VERTICAL.DESKTOP.HEIGHT} + 10px - 12px);
      right: calc(${CARD_PROPERTIES.CARD_VERTICAL.DESKTOP.PADDING} + 16px);
    }
  }

  .card-media__title-container {
    position: absolute;
    top: 16px;
    left: ${CARD_PROPERTIES.PADDING_LEFT};
    text-align: left;
    background-color: transparent;
    max-width: ${CARD_PROPERTIES.CARD_VERTICAL.MOBILE.DESCRIPTION_MAX_WIDTH};
    padding-bottom: 0;

    @media (min-width: ${BREAKPOINT.LG}) {
      top: 24px;
      max-width: ${CARD_PROPERTIES.CARD_VERTICAL.DESKTOP.DESCRIPTION_MAX_WIDTH};
    }
  }

  &.card-media--horizontal {
    // Special case for the "Go mastercard" title with horizontal card image
    .card-media__title-container {
      max-width: 180px;
      @media (min-width: ${BREAKPOINT.LG}) {
        max-width: 240px;
      }
    }
    .card-media__desc {
      padding-right: 80px;
      @media (min-width: ${BREAKPOINT.LG}) {
        padding: 16px calc(100% - ${CARD_PROPERTIES.CARD_VERTICAL.DESKTOP.DESCRIPTION_MAX_WIDTH} - 16px) 50px 16px;
      }
    }
  }

  .card-media__title-container {
    align-items:flex-start;
  }

  .card-media__title {
    font-family: ${FONT_FAMILY.TITLE};
    color: ${CARD_COLOR.BLUE_DARK};
    font-size: 26px;
    line-height: 1.32;
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
    padding: 0;
    width: 100%;

    @media (min-width: ${BREAKPOINT.LG}) {
      font-size: 32px;
      line-height: 1.19;
    }
    sup {
      font-size: .5em;
      top: -1em;
    }
  }

  .card-media__subtitle {
    font-family: ${FONT_FAMILY.TITLE};
    color: ${CARD_COLOR.BLUE_DARK};
    font-size: 20px;
    line-height: 1.32;
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
    padding: 0;
    width: 100%;
  }

  .card-media__content {
    padding: 0;
    z-index: 1;
    color: ${COLOR.GREY75};
  }

  .card-media__desc {
    text-align: left;
    font-family: ${FONT_FAMILY.TITLE};
    font-size: 16px;
    font-weight: 600;
    line-height: 1.38;
    width: 100%;
    display: block;
    padding: 0 calc(100% - ${CARD_PROPERTIES.CARD_VERTICAL.MOBILE.DESCRIPTION_MAX_WIDTH}) 50px 16px;
    margin: 0;
    background-color: ${CARD_COLOR.BLUE_LIGHT};
    overflow: hidden;
    position: relative;

    @media (min-width: ${BREAKPOINT.LG}) {
      padding: 16px calc(100% - ${CARD_PROPERTIES.CARD_VERTICAL.DESKTOP.DESCRIPTION_MAX_WIDTH} - 16px) 50px 16px;
    }

    &:after {
      background-color: ${CARD_COLOR.BLUE_LIGHTER};
      width: 100%;
      height: 70px;
      content: '';
      bottom: -35px;
      left: 0;
      position: absolute;
      transform: skew(0deg, -8deg);
      transform-origin: top-left;

      @media (min-width: ${BREAKPOINT.LG}) {
        height: 60px;
        bottom: -30px;
      }
    }
  }

  .card-media-fees {
    color: ${CARD_COLOR.BLUE_DARK};
    background-color: ${CARD_COLOR.BLUE_LIGHTER};
    min-height: 70px;
    display: flex;
    align-items: center;

    &__meta {
      max-width: 120px;
      margin-left: 8px;

      @media (min-width: ${BREAKPOINT.LG}) {
        &:last-child {
          margin-right: 35px;
        }
      }

      &:after {
        display: none;
      }
    }

    &__unit {
      font-family: ${FONT_FAMILY.TITLE};
      font-size: 36px;
      font-weight: 600;
      line-height: 1.11;
      padding-left: 16px;
    }
  }

  .card-media__list {
    padding: 16px calc(32px + 16px) 16px;
  }

  .card-media__list-item {
    position: relative;
    font-weight: ${FONT_WEIGHT.LIGHT};
    &:before {
      width: 8px;
      height: 8px;
      background-color: ${CARD_COLOR.BLUE_DARK};
      display: block;
      top: 7px;
      left: calc(-${CARD_PROPERTIES.PADDING_LEFT} - 8px);
      border-radius: 50%;
      content: '';
      margin: 0;
    }
  }

  .card-media__buttons {
    justify-content: center;
    > div {
      display: flex;
      width: 100%;
      justify-content: center;
    }
    a {
      width: 134px;
      max-width: 134px;
      padding-left: 4px;
      padding-right: 4px;
    }
  }
}
.card-media__dual_card_layout {
  width:150px !important;
  height:165px !important;
}
.custom_media_button {
    padding: 0 24px 24px;
    justify-content: center;
    display: flex;

  a {
    width: fit-content;
    padding-left: 4px;
    padding-right: 4px;
  }
}
}
}
`;

export default BrandedCard;
